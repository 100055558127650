import dashboardModule from './../../services/dashboard';

export default {
  props: {
    cardType: {
      required: true,
      type: String
    },
    cardParams: {
      type: Object | Array,
      required: true
    }
  },
  data() {
    return {
      dialogFormVisible: false,
      dashboardList: [],
      validationRules: {
        cardName: [
          {
            required: true,
            message: 'Please enter card name',
            trigger: 'blur'
          }
        ],
        newDashboardName: [
          {
            validator: this.newDashboardValidator,
            trigger: 'blur'
          }
        ],
        existingDashboard: [
          {
            validator: this.selectDashboardValidator,
            trigger: 'blur'
          }
        ]
      },
      form: {
        dashboardOption: 'select',
        cardName: '',
        newDashboardName: '',
        existingDashboard: null,
        allowChartOption: false
      },
      creatingCard: false
    };
  },
  methods: {
    fetchDashboardList() {
      dashboardModule
        .fetchDashboardList(this)
        .then((result) => {
          if (result.data.success) {
            this.dashboardList = result.data.data;
          }
        })
        .catch((e) => {
          this.reportError(e);
        });
    },

    newDashboardValidator(rule, value, callback) {
      if (this.form.dashboardOption != 'new') {
        callback();
      }

      this.form.newDashboardName = this.form.newDashboardName.trim();
      if (this.form.newDashboardName.length == 0) {
        callback(new Error('Plese enter new dashboard name.'));
      } else if (this.form.newDashboardName.length > 20) {
        callback(new Error('Dashboard name can not exceed 20 charachters.'));
      } else {
        callback();
      }
    },

    selectDashboardValidator(rule, value, callback) {
      if (this.form.dashboardOption != 'select') {
        callback();
      }

      if (this.form.existingDashboard == null) {
        callback(new Error('Plese select dashboard'));
      } else {
        callback();
      }
    },

    validateInputs() {
      // Validate card name
      this.$refs.newCardForm.validate((valid) => {
        if (valid) {
          this.createCard();
        }
      });
    },

    createCard() {
      if (!this.cardType || !this.cardParams) {
        this.errorToast('Something is wrong, please contact support.');
        this.reportError(new Error('Invalid card type or data.'));
        return;
      }

      let params = {
        cardName: this.form.cardName,
        createNewDashboard: this.form.dashboardOption == 'new',
        dashboardName: this.form.newDashboardName,
        dashboardId: this.form.existingDashboard,
        allowChartOption: this.form.allowChartOption,
        cardType: this.cardType,
        cardParams: this.cardParams
      };

      this.creatingCard = true;
      dashboardModule
        .createCard(params, this)
        .then((result) => {
          if (result.data.success) {
            this.onCancelCreateCardForm();
            this.fetchDashboardList();
            this.successToast('Pin to dashboard succesfully');
          } else if (result.data.message) {
            this.errorToast(result.data.message);
          }
          this.creatingCard = false;
        })
        .catch((err) => {
          this.reportError(err);
          this.creatingCard = false;
          this.errorToast('Something went wrong.');
        });
    },

    onCancelCreateCardForm() {
      this.$refs.newCardForm.clearValidate();
      this.$refs.newCardForm.resetFields();
      this.dialogFormVisible = false;
    }
  },
  created() {
    // get dashboard list
    this.fetchDashboardList();
  }
};
