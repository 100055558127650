<template>
  <el-dialog title="Pin Card To Dashbaord" custom-class="dashboardSelectorPopup" :visible.sync="dialogFormVisible" width="500px">
    <el-form label-position="top" :model="form" :rules="validationRules" ref="newCardForm" label-width="120px" class="demo-ruleForm">
      <!-- Card Name -->
      <el-form-item label="Card Name" prop="cardName">
        <el-input v-model="form.cardName" width="200" placeholder="Enter card name"></el-input>
      </el-form-item>

      <el-form-item label="Dashboard Option" prop="dashboardOption">
        <el-radio-group v-model="form.dashboardOption" class="-mt-3">
          <el-radio label="select">Existing Dashboard</el-radio>
          <el-radio label="new">New Dashboard</el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- Dashboard Dropdown -->
      <el-form-item label="Select Dashboard" prop="existingDashboard" v-if="form.dashboardOption == 'select'">
        <el-select v-model="form.existingDashboard" placeholder="Select Dashboard">
          <el-option v-for="item in dashboardList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="New Dashboard Name" prop="newDashboardName" v-if="form.dashboardOption != 'select'">
        <el-input v-model="form.newDashboardName" placeholder="Enter New Dashboard Name"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="validateInputs()" :loading="creatingCard">Add To Dashboard</el-button>
      <el-button @click="onCancelCreateCardForm()" plain>Cancel</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dashboardSelectorComponent from './dashboardSelectorComponent';
export default dashboardSelectorComponent;
</script>

<style lang="scss" src="./dashboardSelector.scss"></style>
