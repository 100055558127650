export default {
  props: {
    graph: Object,
    duration: String
  },
  data() {
    return {
      funnelData: null
    };
  },
  watch: {
    duration: {
      handler: function () {
        // this.drawBarGraph(this.graph);
      }
    }
  },
  methods: {
    getFunnelStepColor(index) {
      let colors = ['#50B432', '#24CBE5', '#FF9655', '#FFF263', '#6AF9C4', '#058DC7'];
      return colors[index % colors.length];
    }
  },

  mounted() {
    console.log('Incoming data', this.graph);
    this.funnelData = this.graph.data;
  }
};
