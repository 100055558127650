import eventModule from '../../../services/event';
import FiltersMixin from '../../../mixins/filtersMixins';
import draggable from 'vuedraggable';

import BeatLoader from '@/components/loaders/BeatLoader.vue';
const _ = require('lodash');

export default {
  name: 'eventFilter',
  props: {},
  components: {
    draggable,
    BeatLoader
  },
  mixins: [FiltersMixin],
  data() {
    return {
      fetchingData: true,
      drag: false,

      eventList: null,
      selectedEventList: [],
      eventMetadata: null,
      currentEvent: {
        name: null,
        property: null
      },
      currentFocusEvent: {
        name: null,
        property: null
      },

      grwEventPropList: []
    };
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    }
  },

  methods: {
    //Will be called from parent
    getProcessedFilters() {
      let eventList = [];
      this.selectedEventList.forEach((eventFilter) => {
        // Process proeprty filters
        let propertyFilterList = [];
        eventFilter.filters.forEach((propertyFilter) => {
          if (propertyFilter.propertyInfo == null || propertyFilter.value == null) {
            return;
          }
          propertyFilterList.push({
            comparisionType: propertyFilter.comparisionType,
            property: propertyFilter.propertyName,
            propertyColumn: propertyFilter.propertyColumnName,
            value: propertyFilter.value
          });
        });

        eventList.push({
          eventName: eventFilter.name,
          filters: propertyFilterList
        });
      });

      return eventList;
    },

    //#region ----------------------------- Query Builder methods --------------------

    // Fetch event list from server
    fetchEventList() {
      this.fetchingData = true;
      eventModule
        .getEventList(this)
        .then((result) => {
          this.eventList = [
            {
              label: 'Growlytics Events',
              options: this.grwDefaultEventList
            }
          ];

          let list = [],
            listFromServer = Object.keys(result.data.data.eventList);
          for (var i = 0; i < listFromServer.length; i++) {
            if (this.grwDefaultEventList.indexOf(listFromServer[i]) < 0) {
              list.push(listFromServer[i]);
            }
          }
          this.eventList.push({
            label: 'Custom Events',
            options: list
          });

          // Remove all events which are hard coded growlytics events.
          this.fetchingData = false;
          this.eventMetadata = result.data.data.eventList;
          this.grwEventPropList = result.data.data.sessionPropList;
        })
        .catch((err) => {
          this.reportError(err);
          this.errorToast('Something went wrong.');
          this.fetchingData = false;
        });
    },

    onEventSelect() {
      let eventPropertyList = [
        {
          label: 'Growlytics Event Properties',
          options: this.grwEventPropList
        }
      ];
      eventPropertyList.push({
        label: 'Event Properties',
        options: this.eventMetadata[this.currentEvent.name]
      });

      if (this.grwDefaultEventList.indexOf(this.currentEvent.name) !== -1) {
        eventPropertyList.splice(0, 1);
      }

      this.selectedEventList.push({
        name: this.currentEvent.name,
        properties: eventPropertyList,
        filters: []
      });
      this.currentEvent = {
        name: null,
        property: null
      };
    },

    // set eventname on input focus
    setCurrentEventData(eventName, filter) {
      this.currentFocusEvent.name = eventName;
      this.currentFocusEvent.property = filter.propertyInfo.name;
      this.currentFocusEvent.propertyColumn = filter.propertyInfo.columnName;
    },

    // ON +where button click
    addPropertyFilter(eventFilter) {
      eventFilter.filters.push({
        propertyName: null,
        propertyColumnName: null,
        propertyInfo: null,
        comparisionTypeList: null,
        comparisionType: null,
        value: null
      });
    },

    // delete event from selectedEventList
    deleteEventFilter(index) {
      this.selectedEventList.splice(index, 1);
      if (this.selectedEventList.length == 0) {
        this.currentEvent = {
          name: null,
          property: null
        };
      }
    },

    // ON property dropdown change
    onFilterPropertyChange(subFilter, propertyGroupList) {
      let propertyList = [];
      for (let i = 0; i < propertyGroupList.length; i++) {
        propertyList = propertyList.concat(propertyGroupList[i].options);
      }

      // Read proeprty object from property name
      subFilter.propertyInfo = _.find(propertyList, (o) => {
        return o.columnName == subFilter.propertyColumnName;
      });

      subFilter.propertyName = subFilter.propertyInfo.name;

      // Read property's datatype
      let propertyType = subFilter.propertyInfo.type;
      subFilter.comparisionTypeList = this.getComparisonsForDatatype(propertyType);
      subFilter.comparisionType = subFilter.comparisionTypeList[0].key;
    },

    // delete property filter from selectedEventList.filters
    deletePropertyFilter(propertyFilterList, index) {
      propertyFilterList.splice(index, 1);
    },

    //#endregion ----------------------------- Query Builder methods --------------------

    // Fetch event property suggestions
    fetchEventPropertySuggestion(queryString, cb) {
      let params = {
        eventName: this.currentFocusEvent.name,
        property: this.currentFocusEvent.property,
        propertyColumn: this.currentFocusEvent.propertyColumn,
        queryString: queryString
      };
      eventModule
        .fetchCustomEventValueSuggetionsByCol(params)
        .then((result) => {
          if (result.data.success) {
            var links = result.data.data;
            var results = queryString ? links.filter(this.createFilter(queryString)) : links;
            cb(results);
          }
        })
        .catch((e) => {
          cb();
          this.reportError(e);
        });
    },

    createFilter(queryString) {
      return (link) => {
        return link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    }
  },
  mounted() {
    this.fetchEventList();
  }
};
