<template>
  <div class="chartPageSelectorComponent">
    <!-- Chart Selector -->
    <el-select v-model="currentPage" @change="onChartPageChange()">
      <el-option v-for="item in chartPageList" :key="item.key" :label="item.label" :value="item.key">
        <span style="float: left">{{ item.label }}</span>
      </el-option>
    </el-select>
    <br /><br />
  </div>
</template>

<style lang="scss" src="./chartPageSelector.scss"></style>

<script>
import chartPageSelectorComponent from './chartPageSelectorComponent';
export default chartPageSelectorComponent;
</script>
