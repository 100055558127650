<template>
  <div class="w-full" v-if="funnelData">
    <div class="hover:bg-gray-100" v-for="(funnelStep, index) in funnelData" :key="index">
      <div class="flex">
        <div class="pl-4 pr-6 flex-1 pt-3 text-right text-gray-500" :span="6">
          <div v-if="index != 0" style="height: 68px; width: 100%"></div>
          {{ funnelStep.name }}
        </div>
        <div class="overflow-hidden py-2" style="width: 500px">
          <div v-if="index != 0" :span="24" class="text-center overflow-hidden text-gray-400 h-22 mb-2">
            <i class="fa-regular fa-pipe"></i>
            <div class="leading-3 text-sm">{{ funnelStep.percentageFromPrevious }}%</div>
            <i class="fa-regular fa-arrow-down-long"></i>
          </div>
          <div class="mx-auto rounded h-10" :style="{ width: funnelStep.overallPercentage + '%', background: getFunnelStepColor(index) }"></div>
        </div>
        <div class="pl-6 pr-4 flex-1 pt-3 text-gray-500" :span="6">
          <div v-if="index != 0" class="" style="height: 68px; width: 100%"></div>
          {{ funnelStep.overallPercentage }}% &nbsp;&nbsp;&nbsp;&nbsp; {{ funnelStep.count }} customers
        </div>
      </div>
    </div>
  </div>
  <div v-else class="noDatafound" style="text-align: center; padding-top: 15vh; opacity: 0.5">
    <br />
    No data found
  </div>
</template>

<style lang="scss" src="./funnelGraph.scss"></style>

<script>
import FunnelGraph from './funnelGraphComponent';
export default FunnelGraph;
</script>
