<template>
  <div id="funneltPage">
    <!-- Header -->
    <div class="overflow-hidden border border-gray-100 sm:rounded-lg mb-4">
      <div class="bg-gray-50 border-b border-b-gray-200 px-3 py-2 leading-7 text-md">Funnel Analysis</div>

      <!-- Header With Filters -->
      <div class="queryBuilder bg-white border-b border-gray-200">
        <div class="builder">
          <!--Event Property Filter -->
          <div class="column">
            <FunnelStepsFilter ref="funnelStepsRef"></FunnelStepsFilter>
          </div>

          <!-- User Filter -->
          <div class="column" style="border-left: 1px solid #eef0f4; display: none">
            <UserPropertyFilterComponent v-bind:filterTitle="' Users filters'" ref="userFilterComponent"></UserPropertyFilterComponent>
          </div>
        </div>
      </div>

      <div class="pl-2.5 py-2.5 bg-white">
        <el-button type="primary" @click="fetchFunnelGraphData()" :loading="fetchingFunnelGraphdata">Generate Funnel Report</el-button>
        <!-- Completed within time -->
        <div class="timerangeFilter float-right mr-4">
          ...completed within
          <el-input v-model="funnelConversionTime" size="mini" class="numberInput" type="text"></el-input>
          <el-select class="inputDuration" v-model="funnelConversionDurationUnit">
            <el-option label="Days" value="day"></el-option>
            <el-option label="Weeks" value="week"></el-option>
            <el-option label="Months" value="month"></el-option>
          </el-select>
          <!-- from any day &nbsp;&nbsp;&nbsp; ...group by user attribute

        <span class="userProperty">
          <el-select v-model="groupByUserProperty" size="mini" clearable placeholder="Select" @change="fetchFunnelGraphData()" v-if="userPropertyList.length > 0">
            <el-option v-for="item in userPropertyList" :key="item.columnName" :label="item.name" :value="item.columnName" clearable> </el-option>
          </el-select>
        </span> -->

          <!-- Time Range -->
          <div class="datePickerInFilter ml-6">
            <el-select v-model="timingFilter" v-if="timingFilter != ''" size="mini" placeholder="Select" @change="fetchFunnelGraphData()">
              <el-option v-for="(value, key) in chartBuilderTimingOptions" :key="key" :label="value" :value="key"> </el-option>
            </el-select>
            <el-date-picker v-if="timingFilter == ''" v-model="dateFilterValues" size="mini" type="datetimerange" format="dd-MM-yyyy hh:mm:ss A" @change="fetchFunnelGraphData()"></el-date-picker>
          </div>
        </div>
      </div>
    </div>

    <!-- Loader -->
    <div class="loadingDiv" v-if="fetchingFunnelGraphdata" v-loading="true" style="min-height: 300px"></div>

    <!--Chart-->
    <!-- <div class="chartContainer" v-if="$refs.funnelStepsRef && $refs.funnelStepsRef.getProcessedFilters().length > 0 && !fetchingFunnelGraphdata"> -->
    <div class="relative bg-white border rounded-md py-10" v-if="$refs.funnelStepsRef && $refs.funnelStepsRef.getProcessedFilters().length > 0 && !fetchingFunnelGraphdata">
      <!-- // pin icon -->
      <div class="pinToDashboard" @click="showSelectDashboardPopup()">
        <el-tooltip class="item" effect="dark" content="Pin to dashboard" placement="left">
          <i class="fa fa-thumb-tack" aria-hidden="true"></i>
        </el-tooltip>
      </div>
      <FunnelGraph v-if="funnelChartOptions" v-bind:graph="funnelChartOptions"></FunnelGraph>
    </div>

    <!-- Select chart Message -->
    <div class="noChartDataDiv" v-if="$refs.funnelStepsRef && $refs.funnelStepsRef.selectedEventList.length <= 0 && !fetchingFunnelGraphdata">
      <div class="title">Select any step to get started</div>
      <div class="desc">
        Choose only 5 step in the Funnel step panel above.
        <br />Choosing multiple events will allow you to do cross analysis of multiple events.
      </div>
    </div>
    <!-- // dashboard selector popup -->
    <DashboardSelectorComponent ref="dashboardSelector" v-bind:cardType="chartType" v-bind:cardParams="chartParams"> </DashboardSelectorComponent>
  </div>
</template>
<script>
import funnelComponent from './funnelComponent';
export default funnelComponent;
</script>
<style lang="scss" src="./funnel.scss"></style>
