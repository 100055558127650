export default {
  name: 'chartPageSelectorComponent',
  props: {
    page: String
  },
  data() {
    return {
      currentPage: 'events',
      chartPageList: [
        {
          key: 'events',
          label: 'Event Analysis'
        },
        {
          key: 'funnel',
          label: 'Funnel Analysis'
        },
        {
          key: 'revenue',
          label: 'Revenue Analysis'
        }

        // {
        //     key: 'retention',
        //     label: 'Retention Analysis'
        // },
        // {
        //     key: '',
        //     label: 'Cohort'
        // },
        // {
        //     key: 'cohort',
        //     label: 'Cohort'
        // },
      ]
    };
  },
  methods: {
    onChartPageChange() {
      if (this.currentPage == 'events') {
        this.$router.push({
          name: 'ChartList'
        });
      } else if (this.currentPage == 'funnel') {
        this.$router.push({
          name: 'Funnel'
        });
      } else if (this.currentPage == 'revenue') {
        this.$router.push({
          name: 'Revenue'
        });
      }

      // else if (this.currentPage == 'retention') {
      //     this.$router.push({
      //         name: 'Retention'
      //     })
      // }
    }
  },

  mounted() {
    this.currentPage = this.page;
  }
};
