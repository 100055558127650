const moment = require('moment');
import FiltersMixin from './../../mixins/filtersMixins';
import ChartPageSelector from './../../components/chartPageSelector/chartPageSelector';
import UserPropertyFilterComponent from '@/components/chartFilters/userPropertyFilter/userPropertyFilter';
import FunnelStepsFilter from '@/components/chartFilters/funnelStepsFilter/funnelStepsFilter';
import funnelServices from './../../services/funnel';
import customerServices from './../../services/customer';
import FunnelGraph from '@/components/graphs/funnelGraph/funnelGraph';
import DashboardSelectorComponent from './../dashboardSelectorPopup/dashboardSelector';

export default {
  name: 'home',
  mixins: [FiltersMixin],

  components: {
    ChartPageSelector,
    FunnelStepsFilter,
    UserPropertyFilterComponent,
    FunnelGraph,
    DashboardSelectorComponent
  },
  data() {
    return {
      funnelChartType: 'conversion',
      funnelConversionTime: '30',
      funnelConversionDurationUnit: 'day',
      funnelChartOptions: null,
      groupByUserProperty: null,

      // Chart time grouping
      groupByDuration: 'day',
      groupByDurationList: [
        {
          key: 'Hourly',
          value: 'hour'
        },
        {
          key: 'Daily',
          value: 'day'
        },
        {
          key: 'Weekly',
          value: 'week'
        },
        {
          key: 'Monthly',
          value: 'month'
        }
      ],
      userPropertyList: [],

      // Timing properties
      startTime: null,
      endTime: null,
      timingFilter: '10080',
      durationType: 'latest',
      dateFilterValues: [],

      fetchingFunnelGraphdata: false,
      graphData: null,

      // dashboard pinning variables
      chartType: 'funnel_conversion_graph',
      chartParams: null
    };
  },
  methods: {
    showSelectDashboardPopup() {
      this.$refs.dashboardSelector.dialogFormVisible = true;
    },

    fetchFunnelGraphData() {
      let funnelSteps = this.$refs.funnelStepsRef.getProcessedFilters();
      let userFilters = this.$refs.userFilterComponent.getFilters();

      if (funnelSteps.length == 0) {
        this.warningToast('Please select funnel steps!');
        return;
      }

      // Read competion time
      let time = parseInt(this.funnelConversionTime);
      if (isNaN(time)) {
        this.errorToast('Funnel completion time must be a number (like 30 days).');
        return;
      }
      if (time == 'week') {
        time = time * 7;
      } else if (time == 'month') {
        time = time * 30;
      }

      // Build timing filters
      if (this.timingFilter === '') {
        this.startTime = moment(this.dateFilterValues[0]);
        this.endTime = moment(this.dateFilterValues[1]);
        this.durationType = 'exact';
      } else {
        this.startTime = moment().subtract(this.timingFilter, 'minutes');
        this.endTime = moment();
        this.durationType = 'latest';
      }

      //Build params
      let params = {
        userFilters: userFilters,
        funnelSteps: funnelSteps,
        chartType: this.funnelChartType,
        funnelConversionTime: time,
        funnelConversionDurationUnit: this.funnelConversionDurationUnit,
        groupBy: this.groupByUserProperty ? this.groupByUserProperty : null,
        startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
        endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss'),
        durationGroup: this.groupByDuration,
        durationType: this.durationType,
        duration: this.timingFilter,
        version: 1
      };
      // set chart params for dashboard pinning
      this.chartParams = params;

      // Call api
      this.fetchingFunnelGraphdata = true;
      funnelServices
        .getFunnelData(params)
        .then((result) => {
          this.graphData = result.data;
          this.funnelChartOptions = {
            data: this.graphData,
            durationGroup: this.groupByDuration,
            durationType: 'latest',
            duration: this.timingFilter,
            groupBy: params.groupBy
          };
          this.fetchingFunnelGraphdata = false;
        })
        .catch((err) => {
          this.reportError(err);
          if (!err.handled) {
            this.errorToast('Something went wrong.');
          }
          this.fetchingFunnelGraphdata = false;
        });
    },

    fetchUserPropertyList() {
      // Fetch customer properties
      customerServices
        .fetchUserPropertyList(null, this)
        .then((result) => {
          if (result.data.success) {
            this.userPropertyList = result.data.data;
          }
        })
        .catch((error) => {
          this.reportError(error);
        });
    },

    clearFilters() {
      this.$refs.funnelStepsRef.selectedEventList = [];
      this.$refs.funnelStepsRef.currentEvent = {
        name: null,
        property: null
      };
      this.$refs.userFilterComponent.clearFilter();
      this.graphData = null;
      this.fetchingFunnelGraphdata = false;
    }
  },
  mounted() {
    this.dateFilterValues = [moment().subtract(7, 'days').valueOf(), moment()];
    this.fetchUserPropertyList();
    this.fetchFunnelGraphData();
  }
};
